import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import axios from 'axios';
import router from './router/router.js';
import store from './store/store.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@/css/styles.css';

//Import langauge files
import en from "@/lang/en.js"
import nl from "@/lang/nl.js"
import fr from "@/lang/fr.js"

const app = createApp(App);

const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages: {
        en,
        nl,
        fr
    } // set locale messages
});

app.use(store);
app.use(router);
app.use(i18n);
app.mount('#app');

axios.interceptors.request.use(function (request) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    store.commit('SET_savingData', true);
    return request;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    store.commit('SET_savingData', false);
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    store.commit('SET_savingData', false);
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    store.commit('SET_savingData', false);
    return Promise.reject(error);
});
