module.exports = {
    general: {
        pleaseSelect: "Selecteer",
        noneSelected: "Geen geselecteerd",
        delete: "Verwijder",
        edit: "Bewerk",
        loadingData: "Data ophalen van de server...",
        back: "Terug",
        yes: "Ja",
        no: "Nee",
        discard: "Verwijder",
        cancel: "Annuleer",
        confirm: "Bevestig",
        rememberMyChoice: "Onthoud mijn keuze",
        ok: "Ok",
        share: "Deel",
        saveChanges: "Wijzigingen opslaan",
        date: "Datum",
        view: "Bekijk",
        clickHere: "Klik hier",
        attention: "Attentie!",
        optional: "Optioneel",
        save: "Opslaan",
        name: "Naam",
        add: "Toevoegen",
        unknown: "Onbekend",
        next: "Volgende",
        expand: "Uitvouwen",
        collapse: 'Inklappen',
        close: "Sluit",
        email: "E-mail",
        loadingApp: "Applicatie laden...",
        working: "Bezig",
        statusInspection: 'Keuren',
        statusAway: 'Weg',
        statusDiscarded: 'Verwijderd',
        statusAcceptance: 'Acceptatie',
        statusOkForNow: 'Voor nu OK',
        submit: 'Bevestig',
        noItemsToShow: 'Er zijn geen items om te tonen.',
        rename: 'Hernoemen',
        selectAll: 'Selecteer alles',
        deselectAll: 'Deslecteer alles',
        moreOptions: 'Meer opties',
        savingData: 'Items opslaan...',
        none: 'Geen',
        previous: 'Vorige',
        showAll: 'Toon alle {0}',
        showLess: 'Toon minder',
        typeHere: 'Typ hier...',
        users: 'gebruikers'
    },
    configurator: {
        configurator: "Configurator",
        configure: "Stel samen",
        noRibbons: "Voor dit model zijn geen biezen te configureren",
        colorWarning: "De kleur van het product kan afwijken door belichting en monitor instellingen.",
        selectedColor: "Geselecteerde kleur:",
        ribbonPreviewTip: "Voor een nauwkeurigere voorvertoning van de kleur en het patroon van de bies, zie de rechter bovenhoek.",
        material: "Materiaal",
        lengthAdjustment: "Lengte aanpassing",
        noAdjustment: "Geen aanpassing",
        extraOverlap: "Extra overlap",
        monogramLine: "Monogram tekst, lijn",
        monogramStitchcolor: 'Monogram kleur borduursel',
        monogramStitchcolorWarning: 'Als de gekozen kleur niet beschikbaar is, mag de fabriek een vergelijkbare kleur kiezen.',
        monogramVelcro: "Monogram op klittenband",
        armholeAdjustment: "Armsgat aanpassingen",
        customization: 'Personalisering',
        protectiveMaterial: 'Beschermend materiaal'
    },
    quotation: {
        quotationOverview: "Offerte overzicht",
        thanksForRequest: "Hartelijk dank voor uw offerte aanvraag! Binnen enkele ogenblikken ontvangt u een bevestiging per e-mail. Wij zullen uw offerte z.s.m. opmaken. Indien u vragen heeft over de offerte, neemt u alstublieft contact op met ",
        contactSales: " Sales & Logistics.",
        emptyCart: "Uw offerte is leeg. Begin met het samenstellen van een loodschort",
        total: "Totaal",
        configureAnother: "Stel nog een samen",
        requestQuotation: "Vraag offerte aan",
        configureYourModel: "Stel uw {0} samen",
        skirtIdentical: "Rok identiek",
        vest: "Vest",
        skirt: "Rok",
        urlCopied: "De URL is naar het klipbord gekopieerd ",
        quantity: "Aantal",
        addToQuote: "Toev. aan offerte",
        formIncomplete: "Alle onderdelen, behalve Personalisering, zijn verplicht.",
        include: 'Bevat',
        favoriteSaved: 'Uw favoriet is opgeslagen',
        goToFavorites: 'Ga naar favorieten',
        favoriteSavingFailed: "Favoriet kon niet worden opgeslagen, probeer het later opnieuw'",
        shareTitle: 'Ray iQ Configurator',
        shareText: 'Bekijk mijn samengesteld loodschort in Ray iQ',
        comments: 'Vragen/Opmerkingen'
    },
    addEditApron: {
        manufacturer: "Fabrikant",
        model: "Model",
        serial: "Serienummer",
        size: "Maat",
        fabricColor: "Stofkleur",
        ribbonColor: "Bieskleur",
        selectModelFirst: "Selecteer eerst een model",
        notAvailable: "Geen {0} beschikbaar voor dit model",
        fabrics: "stoffen",
        ribbons: "biezen",
        importDate: "Geïmporteerd op",
        productionDate: "Productiedatum",
        nameOfUser: "Naam van gebruiker",
        department: "Afdeling",
        manageDepartments: "Beheer uw afdelingen op de ",
        settingsPage: "instellingen pagina",
        monogram: "Monogram",
        line1: "Lijn 1",
        pbEquivalence: "Loodequivalent",
        front: "Voor",
        back: "Achter",
        uncategorized: 'Geen categorie',
        apronsSkirtsVests: 'Jassen, rokken en vesten',
        multipleSelected: 'Meerdere {0} geselecteerd, typ om te overschrijven',
        thyroidCollarsOthers: 'Schildklierkragen, brillen & overige',
        multipleModelsSelected: 'Meerdere modellen geselecteerd',
        multipleManufacturesSelected: "Meerdere fabrikanten geselecteerd",
        multipleSizesSelected: "Meerdere maten geselecteerd. Verander om te overschrijven.",
        multipleUsersSelected: "Meerdere gebruikers geselecteerd. Typ om te overschrijven.",
        multipleDepartmentSelected: "Meerdere afdelingen geselecteerd",
        multipleColorsSelected: "Meerdere kleuren geselecteerd",
        multipleMonogramsSelected: "Meerdere monogrammen geselecteerd, typ om te overschrijven.",
        differentValuesSelected: "Meerdere waardes geselecteerd",
        onlyAvaibleForScanFlex: "Alleen beschikbaar voor Scanflex items",
        ModelConvertedToDefault: 'Vanwege een verandering in selectie van fabrikant zijn de modellen van sommige items gereset naar "Other"',
        exampleName: "Voorbeeld: Linda de Jong",
        exampleDepartment: "Voorbeeld: Radiologie",
        inventoryNumber: "Inventarisnummer",
        suggested: 'Voorgesteld',
        alphabetical: 'Alfabetisch'
    },
    confirmBackToInventory: {
        areYouSure: "Weet u zeker dat u deze verwijderde items terug wilt verplaatsen naar de uw inventaris?"
    },
    confirmDiscard: {
        areYouSure: "Weet u zeker dat u deze items wilt verwijderen? De items zullen naar de verwijderde items lijst verplaats worden."
    },
    accountConfig: {
        welcome: "Welkom",
        thankYou: "Bedankt voor uw registratie. Om Ray iQ volledig te benutten neemt deze wizard u mee door de configuratie van uw account. Kies uw voorkeurstaal",
        initialStatus: "Standaard status voor nieuwe items",
        initialStatusQuestion: "Wilt u ingangscontroles doen voor ieder nieuw toegevoegde item? (Deze instelling kan later aangepast worden op de Instellingen pagina.)  ",
        tolerances: "Toleranties",
        toleranceInstruction: "Stel de tolerantie in voor de prestaties van uw items.",
        toleranceInfoMessage: "Toleranties kunnen u helpen beslissen of een item nog goedgekeurd mag worden. Items die de geselecteerde waarde, of een hogere waarde, scoren worden goedgekeurd.",
        protectiveQualities: "Mate van bescherming",
        conditionFasteners: "Bevestigingsmateriaal",
        itemLimitReached: 'U heeft de inventaris limiet van {0} items bereikt.'
    },
    reportOverview: {
        hygiene: "Hygiëne",
        reportOverview: "Rapportoverzicht",
        apronDetails: "Details",
        loadingApronData: "Item gegevens laden...",
        history: "Historie",
        show: "Toon",
        baseline: "Baseline",
        protection: "Bescherming",
        fasteners: "Bevestigingsmateriaal",
        reports: "Rapport | Rapporten",
        notes: "Notities",
        photos: "Foto's",
        noReports: "Er zijn geen rapporten voor dit item.",
        fileFirstReport: "om het eerste rapport te maken voor dit item.",
        viewImages: "Bekijk afbeeldingen",
        areYouSureDelete: "Weet u zeker dat u dit rapport wilt verwijderen? Dit kan niet ongedaan gemaakt worden.",
        deleteFailed: "Het rapport kon niet worden verwijderd.",
        footNote: 'Gebruikersnaam alleen beschikbaar voor rapporten gemaakt na juli 2024'
    },
    dashboardHome: {
        dashboard: "Dashboard",
        numberOfItems: "Totaal aantal items",
        inspectionItems: "Te keuren items",
        importItems: "Items klaar voor import",
        inspectionListHeader: "Items die aandacht nodig hebben",
        emptyInspectionList: "Er zijn momenteel geen items die aandacht nodig hebben.",
        myInventory: "Mijn inventaris",
        noItemsWarning: "Uw inventaris is leeg. Gebruik de Item toev. knop om uw eerste item toe te voegen.",
        importScanflexTip: "Gebruik de Importeer knop links bovenaan om via Cablon Medical aangeschafte Scanflex items toe te voegen aan uw inventaris.",
        import: "Importeer",
        accountConfig: "Account instellingen",
        editApron: "Bewerk item",
        addApron: "Item toev.",
        discardItems: "Item(s) verwijderen",
        importFailed: "De import kon niet worden uitgevoerd. Neem alstublieft contact met ons op via info@ray-iq.com",
        inventoryLimitReached: 'Inventaris liemiet bereikt!',
        bulkEditAprons: 'Bulk bewerken'
    },

    apronShortlist: {
        awayForRepairs: "Weg voor reparatie",
        settings: "Instellingen",
        status: "Status",
        sn: "S/N",
        fabric: "Stof",
        ribbon: "Bies",
        user: "Gebruiker",
        lastInspection: "Laatste keuring",
        noResults: "Uw zoekopdracht heeft geen resultaten opgeleverd.",
        defaultEmptyListText: "Uw inventaris is leeg.",
        fileReportForApron: "Wilt u een rapport maken voor het zonet van reparatie teruggekeerde schort?",
        inventoryNumber: 'Inventarisnummer',
        nextInspection: "Volgende keuring"
    },
    imageList: {
        loadingImages: "Afbeeldingen worden geladen..."
    },
    privateMenu: {
        discardedItems: "Verwijderde items",
        logOut: "Uitloggen"
    },
    productSelector: {
        selectProduct: "Selecteer product",
        frontProtection: "Front-bescherming",
        allRoundProtection: "Rondom-bescherming",
        thyroidCollars: "Schildklierkragen",
        popularChoice: "Populaire <br /> keuze!",
        open: "Openen",
        areYouSureDeleteFavorite: "Weet u zeker dat u de favoriet genaamd '{0}' wilt verwijderen?",
        added: 'Toegevoegd',
        colorScheme: 'Kleurstelling',
        renameFavorite: 'Geef je favoriete configuratie een nieuwe naam',
        renameFavoriteTitle: 'Hernoem favoriet ',
        nameFavorite: 'Geef je favoriete configuratie een naam, sla deze op en vind je configuratie terug in het productoverzicht.',
        enterName: 'Voer een naam in...'
    },
    questionnaire: {
        veryBad: "Erg slecht",
        bad: "Slecht",
        acceptable: "Acceptabel",
        good: "Goed",
        veryGood: "Erg goed",
        protectiveQualitiesInstruction: "Controleer met behulp van fluoroscopie of het beschermende materiaal in tact is. Zowel de grootte als de locatie van defecten is belangrijk. Op basis van de uitkomst van de fluoroscopie kunt u beslissen om het item goed te keuren, af te keuren of onder observatie plaatsen.",
        hygieneInstruction: "Bekijk het item en beoordeel of het item goed te reinigen is. Bekijk of het buitenmateriaal nog in tact is. Besteed extra aandacht aan lastig schoon te maken delen van stralingsbeschermende kleding, met name de naden en de biezen. ",
        fastenerInstruction: "Test of het klittenband goed sluit en niet te makkelijk los laat. Beoordeel ook of de stiksels rond het klittenband nog goed vast zitten. Als het item een band heeft, bekijk dan of deze in goede staat is."
    },
    textWithDatepicker: {
        title: "Datum volgende keuring",
        lessYearWarning: "De volgende keuring moet binnen een jaar plaats vinden."
    },
    reportPage: {
        outOfToleranceWarning: "Uw {0} valt buiten de tolerantie",
        notesAndPhotos: "Notities en foto's",
        uploading: "Uploaden...",
        upload: "Upload",
        fileRestirctions: "JPG, PNG of BMP. Maximaal 5 foto's en 4MB per foto",
        currentFiles: "Geselecteerde bestanden",
        discardApron: "Verwijder item",
        outOfToleranceReminder: "Dit item valt buiten de tolerantie om de volgende reden(en)",
        approveAnywayMessage: "Gebaseerd op deze keuring dient dit item verwijderd te worden. U kunt ervoor kiezen om dit item nog in de inventaris te houden, totdat er vervanging beschikbaar is.",
        keepInInventory: "Houd in inventaris",
        keptInInventoryMessage: "U hebt ervoor gekozen om dit item in de inventaris te houden, ondanks dat het item buiten de tolerantie valt. Het is aanbevolen om binnen korte tijd weer een keuring van dit item te plannen.",
        scheduleNextInspection: "Plan volgende keuring",
        narrowestOfMargins: "Dit item is maar nét goedgekeurd. Het is aanbevolen om dit item goed in de gaten te houden. Hier kunt u de volgende keuring inplannen.",
        surplusIgnored: "U heeft meer dan 5 bestanden geselecteerd. Het overschot wordt niet geupload.",
        fileTooLarge: "Het bestand {0} is te groot en wordt overgeslagen.",
        compressedArchves: "Gecomprimeerde bestanden worden niet ondersteund. Het bestand {0} wordt overgeslagen.",
        noToleranceMessage: "De tolerantie waarden moeten worden ingesteld alvorens u rapporten kunt maken. Ga naar de {0} om de tolerantie waarden in te stellen."
    },
    manageDepartments: {
        noOfAprons: "Aant. items",
        addDepartment: "Afdeling toev.",
        areYouSureDelete: "Weet u het zeker dat u de afdeling {0} wilt verwijderen? Deze actie verwijdert niet de aan de afdeling gekoppelde items.",
        ownerWarning: "Alleen de 'eigenaar' van een ziekenhuis kan afdelingen bewerken in Ray iQ. De eigenaar van uw ziekenhuis in Ray iQ is {0}'"
    },
    searchBox: {
        placeholder: "Zoek..."
    },
    tableFilters: {
        filterHeading: "Inventaris Filteren",
        size: "Maat",
        status: "Status",
        model: "Model",
        manufacturer: "Fabrikant",
        importDate: 'Geïmporteerd op',
        nextInspection: 'Volgende keuring',
        user: 'Gebruiker',
        department: 'Afdeling',
        awayForRepairs: 'Weg voor reparatie',
        monogram: 'Monogram',
    },
    tableSettings: {
        columns: "Kolommen",
        chooseColumns: "Kies welke van de volgende kolommen er getoond moeten worden:",
        tableSettings: 'Tabel instellingen'
    },
    apronDetail: {
        sizeAdjustments: "Maat aanpassingen",
        leadEquivalence: "Lood-equivalent"
    },
    previewPlusDetails: {
        returnToDashboard: " terug naar het dashboard",
        fabricColorUnknown: "Stofkleur onbekend",
        fabricAndRibbonColorUnknown: "Stof- en bieskleur onbekend",
        ribbonColorUnknown: "Bieskleur onbekend",
        apronNotFound: "Item niet gevonden. Ga alstublieft {0}",
        editDetails: 'Details bewerken'
    },
    quoteDetail: {
        sizing: "Maatvoering",
        garmentSize: "Maat",
        customization: "Personalisering"
    },
    readMore: {
        readMore: "Lees meer"
    },
    inviteColleague: {
        accepted: "Geaccepteerd",
        pending: "In afwachting",
        addAcolleague: 'Nodig collega uit',
        invitationSent: "De uitnodiging is verstuurd.",
        welcomeText: "Nodig een collega uit om deel te nemen aan uw ziekenhuis {0}.",
        ownerWarning: "Alleen de 'eigenaar' van een ziekenhuis mag nieuwe teamleden uitnodigen en rechten bewerken. De eigenaar van uw ziekenhuis in Ray iQ is {0}",
        rights: 'Rechten',
        editUserRights: 'Bewerk gebruikersrechten',
        sendInvite: 'Stuur uitnodiging',
        areYouSureDelete: 'Weet u zeker dat u gebruiker {0} wilt verwijderen?',
        owner: 'Instituut eigenaar'
    },
    settings: {
        account: 'Account',
        general: 'Algemeen',
        currentPassword: 'Huidig wachtwoord',
        newPassword: 'Nieuw wachtwoord',
        confirmPassword: 'Bevestig wachtwoord',
        inspectionNeeded: 'Keuren',
        afterRepairs: 'Status voor items die terugkeren van reparatie:',
        emailNotifications: 'E-mail notificaties',
        inspectionReminders: 'Herinneringen voor keuringen',
        newsUpdates: 'Nieuwsbrief (features etc.)',
        inviteColleague: 'Nodig collega uit',
        passwordUpdateError: 'Uw wachtwoord kon niet bijgewerkt worden door de onderstaande reden(en):',
        passwordsDontMatch: 'Het nieuwe wachtwoord en de bevestiging komen niet overeen.',
        passwordCriteria: 'Het wachtwoord moet minimaal 8 tekens lang zijn en moet tenminste één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten ($!%*?&).',
        emailMayNotBeEmpty: 'Het veld "e-mail" mag niet leeg zijn.',
        afasReminders: 'Herinnering import aangeschafte items',
        saveMessage: 'Alle instellingen (behalve notificatie instellingen) zijn globaal. Deze beinvloeden uw hele instituut.',
        FiveThreePointScore: 'Vijfpunts score systeem'
    },
    worklist: {
        worklist: 'Werklijst',
        reportSaved: 'Rapport opgeslagen',
        reportSavedSuccessfully: 'Het rapport is succesvol opgeslagen. Om een volgend item te keuren, kies een item uit de werklijst.',
        discardedMessage: 'Uw {0} is verwijderd. U kunt een vervangend item samenstellen in de {1}',
        noItemsNeedAttention: 'Er zijn momenteel geen items die aandacht nodig hebben.'
    },
    passwordFields: {
        password: 'Wachtwoord',
        fillPasswordPlease: 'Vul a.u.b. een wachtwoord in'
    },
    confirmEmail: {
        confirmationFailed: 'E-mail bevestiging mislukt',
        contactUsToResolve: 'Uw e-mail adres kon niet bevestigd worden. Neem a.u.b. contact op met ons via info@ray-iq.com voor toegang tot uw account.',
        visitHomePage: 'Klik hier om de homepage te bezoeken.',
        confirmingEmail: 'E-mail adres bevestigen',
        pleaseWaitConfirm: 'Een moment geduld terwijl wij uw e-mail adres bevestigen.',
        confirmationSuccess: 'E-mail adres bevestigd',
        redirectInProgress: 'U wordt nu doorgestuurd naar de login pagina.',
        forceRedirect: 'Klik hier als u niet automatisch wordt doorgestuurd.'
    },
    contact: {
        contactUs: 'Neem contact op',
        suggestionsAndIssues: 'Als u suggesties heeft voor de applicatie, of indien u problemen ondervindt, neem dan contact op via:',
        salesAndLogistics: 'Sales & Logistics',
        salesRelatedQuestions: 'Indien u verkoop-gerelateerde vragen heeft, neem dan contact op via:',
        ourLocation: 'Adresgegevens',
        theNetherlands: 'Nederland'
    },
    forgotPassword: {
        forgotPassword: 'Wachtwoord vergeten',
        enterEmail: 'Vul uw e-mail adres in om een nieuw wachtwoord te genereren.',
        checkEmailMessage: 'Klik op de link in de e-mail om uw wachtwoord opnieuw in te stellen.'
    },
    landingspage: {
        intro: 'Ray iQ is een door Cablon Medical ontwikkelde webapp die jaarlijkse keuring en administratie van stralingsbeschermende kleding makkelijker én overzichtelijker maakt. Via deze app ontvangt u automatisch reminders voor de jaarlijkse controles en creëert u eenvoudig de rapportages per item. Grote en ingewikkelde Excelbestanden worden hiermee overbodig! Ook is er een kleding configurator in de app opgenomen. Zo kunt u zelf gemakkelijk uw kleding samenstellen en gelijk zien hoe deze eruit komt te zien',
        KeepTrackTitle: 'Uw stralingsbeschermend materiaal inzichtelijk',
        HowToGetStarted: 'Hoe kan ik aan de slag?',
        packedWithFeatures: 'Vol met mogelijkheden',
        easyReporting: 'Eenvoudige rapportage',
        automaticReminders: 'Automatische reminders',
        apronConfigurator: 'Loodschort configurator',
        rayiqAvailabilityMessage: 'Ray iQ is momenteel beschikbaar voor alle Cablon Medical klanten met een exclusief contract voor de afname van Scanflex stralingsbeschermende kleding en accessoires. Geinteresseerd? Neem contact op met uw account manager.',
        call: 'Bel',
        mail: 'Mail',
        tryConfigurator: 'Probeer gratis de configurator',
        rayiqExplained: 'Ray iQ uitgelegd',
        easyImport: 'Eenvoudige import',
        upToDateInventory: 'Up-to-date inventaris',
        easyImportText: 'Al uw aangeschafte Scanflex items worden automatisch in Ray iQ opgenomen',
        easyReportingText: 'Eenvoudige rapportage met instelbare afkeurcriteria bij jaarlijkse controles',
        automataticRemindersText: 'Automatische reminders voor jaarlijkse controles',
        apronConfiguratorText: 'Nieuwe kleding samenstellen en offertes aanvragen in de configurator',
        upToDateInventoryText: 'Actueel overzicht van al uw stralingsbeschermende kleding'
    },
    login: {
        login: 'Log in',
        register: 'Registreer',
        createAccount: 'Maak account',
        forgotPassword: 'Wachtwoord vergeten?'
    },
    publicMenu: {
        toDashboard: 'Naar Dashboard',
    },
    resetPassword: {
        resetPassword: 'Reset wachtwoord',
        enterPassword: 'Voer het nieuwe wachtwoord in.',
        resetFailed: 'Wachtwoord reset mislukt',
        resetFailedExplanation: 'Uw wachtwoord kon niet opnieuw ingesteld worden. Tip: wachtwoord reset links kunnen maar eenmalig gebruikt worden.',
        clickHereTryAgain: 'Klik hier om het nogmaals te proberen.',
        resetSuccessful: 'Wachtwoord succesvol hersteld',
        loginAndRedirectPending: 'U wordt nu ingelogd en doorverwezen naar het dashboard.'
    },
    discardedItems: {
        backInInventory: 'Zet terug in inventaris'
    },
    importFromExcel: {
        title: 'Importeer items vanuit Excel template',
        stepOne: 'Download de template en vul deze met uw data. Indien u al een template heeft gevuld, klink dan op volgende.',
        downloadTemplate: 'Download template',
        stepTwo: 'Sleep de Excel template naar de regio hier onder, of klik op de upload knop om een template bestand te selecteren.',
        dropTemplate: 'Laat de template hier los',
        acceptedFiles: '(Alleen XLS of XLSX bestanden. Maximale grootte: 4MB.)',
        tooManyFiles: 'U kunt één bestand tegelijk uploaden.',
        nonValidExtention: 'Ongeldig bestand.',
        fileTooLarge: 'Het bestand {0} is te groot. Upload uw data in meerdere keren, alstublieft.',
        releaseMouseBtn: 'Laat de muisknop los om het bestand te uploaden.',
        processingExcel: 'Uw Excel wordt verwerkt.',
        maxRows: 'Er kunnen maximaal 1000 rijen per keer verwerkt worden.',
    },
    compareImportedData: {
        title: 'Importeer Excel',
        manufacturerConverted: 'Fabrikant (Geconverteerd)',
        modelConverted: 'Model (Geconverteerd)',
        addToInventory: 'Voeg toe aan inventaris',
        errors: 'Onvolledig',
        converted: 'Behoeft controle',
        changeManufacturerName: 'Wilt u alle items genaamd {0} veranderen in {1}?',
        itemSkipped: 'Er is {n} item overgeslagen, omdat het serienummer al bestaat | Er zijn {n} items overgeslagen, omdat deze serienummers al bestaan',
        itemDuplicate: 'Er is {n} dubbel item genegeerd in de Excel template | Er zijn {n} dubbele items genegeerd in de Excel template ',
        largeExcel: 'Er is {n} item overgeslagen, omdat het Excel bestand meer dan 1000 rijen bevat. | Er zijn {n} items overgeslagen, omdat het Excel bestand meer dan 1000 rijen bevat.',
        addingItems: 'De items worden toegevoegd aan uw inventaris. Sluit dit scherm niet.',
        addingComplete: 'De items zijn toegevoegd aan uw inventaris. De items zijn zichtbaar op het dashboard. U wordt hier binnen enkele seconden naartoe geleid.',
        errorsDuringAdd: 'Er zijn {a} item(s) toegevoegd aan uw inventaris. {n} item kon niet worden toegevoegd. Probeer het alstublieft opnieuw. Indien het probleem aanhoudt, neemt u dan alstublieft contact op via | Er zijn {a} item(s) toegevoegd aan uw inventaris. {n} items kon niet worden toegevoegd. Probeer het alstublieft opnieuw. Indien het probleem aanhoudt, neemt u dan alstublieft contact op via ',
        limitExceeded: 'Er zijn {0} items overgeslagen, omdat de inventaris limiet is bereikt.'
    },
    cookieNotice: {
        message: 'Door in te loggen accepteert u functionele cookies.'
    },
    departmentRights: {
        rightsDescription: 'Wanneer u stralingsbeschermende kleding heeft toegewezen aan afdelingen, kunt u per gebruiker bepalen bepalen of zij de items van deze afdeling(en) mogen zien. Gebruik de vinkjes hier onder om aan tegeven tot welke afdelingen de gebruiker toegang heeft.'
    },
    inviteRights: {
        atLeastOneDept: 'U dient ten minste toegang te geven tot één afdeling.',
        emailColleague: 'E-mailadres van uw collega'
    },
    pageNotFound: {
        title: " Pagina niet gevonden...",
        message: "Sorry, de pagina die u zoekt bestaat niet, of is verplaatst.",
        buttonText: "Naar de home page."
    },
    freeTextInput: {
        errorMessage: 'Dit veld mag niet leeg gelaten worden',
    },
    testimonials: {
        title: 'Wat anderen zeggen',
        functionTitle0: 'Head of Radiation Physics & Health Physics',
        functionTitle1: 'Toeziendhoudend stralingsdeskundige',
        testimonial0: `De Ray iQ applicatie geeft ons, t.o.v. onze vorige simpele, beperkt overzichtelijke lijst, een dashboard met een duidelijk overzicht van alle items die goedgekeurd of te keuren zijn. Door dit visueel aspect en eenvoud in gebruik is de overschakeling naar de nieuwe applicatie vlot verlopen.
De applicatie is door onze organisatie als een verbetering ervaren omwille van, onder andere, volgende punten. Elke dienst kan zijn eigen lijst visualiseren terwijl de verantwoordelijke een overzicht behoudt over alle PBMs in de organisatie. De criteria voor afkeuren zijn per organisatie in te stellen en in de configurator kunnen er favorieten gecreëerd worden zodat alle diensten dezelfde kleur voor dezelfde maat bestellen. Dit geeft tijdswinst bij het kiezen van de juiste PBM. In de applicatie wordt ook de geschiedenis per item bijgehouden wat de <mark> mate van verergeren van schade in de tijd duidelijk maakt en de beslissing tot afkeuren uniformeert.</mark> De maandelijkse mail met overzicht van de te keuren items per dienst is eveneens als een pluspunt ervaren.`,
        testimonial1: `Wij hebben de afgelopen periode de loodschort controles uitgevoerd met Ray iQ. Wat een verademing! 
Alle items overzichtelijk in één database en <mark>met een paar klikken creëer je een beoordelingsrapport en weet je of een item afgekeurd moet worden.</mark>
En wat helemaal mooi is meegenomen, is het feit dat je via Ray iQ ook direct weer de vervangende items kan bestellen.
Al met al een hele verbetering dankzij Ray iQ.`,
    }
};
