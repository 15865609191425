<template>
	<div>
		<Modal></Modal>
		<!--This is the container for our whole app, which will ALWAYS be visible, sort of like what _Layout.vbhtml was in UO-->
		<RouterView />
	</div>
</template>

<script>
	import Modal from "@/private/components/ModalContent/Modal";
	
	export default {
		name: 'App',
		components: {
			Modal,
			//CookieNotice
			//LocaleSwitcher
		},
		data() {
			return {

			}
		},
		methods: {

		},
		mounted: function () {
			
		},
		watch: {
			'$store.state.config.userPreferences.languageSelection': function (newValue) {
				this.$i18n.locale = newValue
			},


		},
	}
</script>

<style>

</style>
