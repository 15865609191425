<template>
    <div v-if="showModal == true">
        <div class="bg-modal" id="bg-modal">
            <div class="content-modal" id="content-modal">
                <div class="header-modal">
                    <h2>{{title}}</h2>
                    <i class="bi bi-x-circle-fill color-red" v-if="allowClose" @click="close"></i>
                </div>
                <div class="p-4 max-height-80vh ">
                    <component :is="component" v-bind="ComponentData"></component>
                    <component v-if="component2 != null" :is="component2" v-bind="component2data"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Bus from '@/Bus.js';


    export default {
        name: 'Modal',
        components: {
         
        },
        props: {

        },
        data: function () {

            return {
                showModal: false,
				ComponentData: {},
                title: null,
                component2: null,
                component2data: {},
				allowClose: true
            }

        },
        methods: {
            open() {
                this.showModal = true;
               
            },
            close() {
                this.showModal = false;
            },

            //Event handler for distinguisd elements. 
            ClickOutsideModal: function (event) {
                var elem = event.currentTarget;

                // if background is clicked
                if (elem.id == "bg-modal") {
					if (this.allowClose) {
						this.close();
					}
                }
                //stop bubbling up
                event.stopPropagation();
            },

            //distinguish background and content of modal and add event listener
            SearchElem: function () {
                var elems = document.querySelectorAll("div#bg-modal, div#content-modal");
                for (let elem of elems) {
                    elem.addEventListener("mousedown", this.ClickOutsideModal);
                }
            },



            //Set the data that is passed from emitted event
			set({data, objComponent, title}, component2 = undefined, component2data = undefined, allowClose = true) {

                this.title = title;

                this.component = objComponent;
                this.ComponentData = data;

                this.component2 = component2
                this.component2data = component2data

                this.allowClose = allowClose
            }
        },
        mounted: function () {
            Bus.on('set-modal-data', this.set);
            Bus.on('open-modal', this.open)
			Bus.on('close-modal', this.close)
        },
        updated: function(){
            this.SearchElem();
        }
    }
</script>

