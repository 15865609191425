import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store.js';

import Bus from '@/Bus.js';

const LAA = 'Ray iQ - ';

const routes = [
    {
        //Front page, registration and other public pages
        path: '/',
        component: () => import(/* webpackChunkName: "Home" */ '@/public/views/Home.vue'),
        meta: { title: LAA + 'Home' },
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import(/* webpackChunkName: "Landingspage" */ '@/public/components/Landingspage.vue'),
                meta: {
                    title: LAA + 'Home',
                },
            },
            {
                path: '/about',
                name: 'About',
                component: () => import(/* webpackChunkName: "about" */ '@/public/components/About.vue')
            },
            {
                path: "/Login",
                name: "Login",
                component: () => import(/* webpackChunkName: "Login" */ '@/public/components/Login.vue')
            },
            {
                path: "/Register/:InstitutionID?",
                name: "Register",
                component: () => import(/* webpackChunkName: "Register" */ '@/public/components/Register.vue')
            },
            {
                path: "/ForgotPassword",
                name: "ForgotPassword",
                component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/public/components/ForgotPassword.vue')
            },
            {
                path: "/ResetPassword",
                name: "ResetPassword",
                component: () => import(/* webpackChunkName: "ResetPassword" */ '@/public/components/ResetPassword.vue')
            },
            {
                path: "/ConfirmEmail",
                name: "ConfirmEmail",
                component: () => import(/* webpackChunkName: "ConfirmEmail" */ '@/public/components/ConfirmEmail.vue')
            },
            {
                path: "/Elearning",
                name: "E-learning",
                component: () => import(/* webpackChunkName: "Elearning" */ '@/public/components/E-learning.vue')
            },
            {
                path: "/Contact",
                name: "Contact",
                component: () => import(/* webpackChunkName: "Contact" */ '@/public/components/Contact.vue'),
                meta: {
                    title: LAA + 'Contact',
                },
            },

		]
	},
	{
		//Publically available configurator
        path: '/Configurator', name: 'Configurator_p', component: () => import( /* webpackChunkName: "Configurator" */ "@/private/components/Configurator/Configurator.vue"), meta: { title: LAA + 'Configurator' }, children: [
            { path: 'ProductSelector/:category?', props: (route) => ({ category: route.params.category || 2 }), name: 'ProductSelector_p', component: () => import( /* webpackChunkName: "ProductSelector" */ "@/private/components/ProductSelector"), meta: { title: LAA + 'Product selector' } },
			{ path: 'Quotation/:sku?/:sku2?/:sku3?', name: 'Quotation_p', component: () => import( /* webpackChunkName: "Quotation" */ "@/private/components/Quotation"), meta: { title: LAA + 'Request quotation' } },
		]
	},
	{
		//Private part of Ray iQ
		path: "/LAA",
		component: () => import(/* webpackChunkName: "LAA" */ '@/private/views/LAA.vue'),
		meta: { requiresAuth: true },
		children: [
			{ path: '', name: 'DashboardHome', component: () => import( /* webpackChunkName: "DashboardHome" */ "@/private/components/DashboardHome"), meta: { requiresAuth: true, title: LAA + 'Dashboard' } },
			{ path: 'DiscardedItems', name: 'DiscardedItems', component: () => import( /* webpackChunkName: "DiscardedItems" */ "@/private/components/DiscardedItems"), meta: { requiresAuth: true, title: LAA + 'Discarded items' } },
			{
				path: 'Settings', name: 'Settings', component: () => import( /* webpackChunkName: "Settings" */ "@/private/components/Settings"), meta: { requiresAuth: true, title: LAA + 'Settings' }
			},
			{ path: 'Overview', name: 'Overview', component: () => import( /* webpackChunkName: "QuoteOverview" */ "@/private/components/Configurator/QuoteOverview.vue"), meta: { requiresAuth: true, title: LAA + 'Quotation overview' } },
			{
				path: 'Configurator', name: 'Configurator', component: () => import( /* webpackChunkName: "Configurator" */ "@/private/components/Configurator/Configurator.vue"), meta: { requiresAuth: true, title: LAA + 'Configurator' }, children: [
					{ path: 'ProductSelector/:category?', props: (route) => ({ category: route.params.category || 2 }), name: 'ProductSelector', component: () => import( /* webpackChunkName: "ProductSelector" */ "@/private/components/ProductSelector"), meta: { requiresAuth: true, title: LAA + 'Lead Apron overview' } },
                    { path: 'Quotation/:category?/:favouriteid?/:sku?/:sku2?/:sku3?', props: (route) => ({ favouriteid: route.params.favouriteid || 2 }), name: 'Quotation', component: () => import( /* webpackChunkName: "Quotation" */ "@/private/components/Quotation"), meta: { requiresAuth: true, title: LAA + 'Request quotation' } },
				]
			},
			{ path: 'ReportPage/:slug', name: 'ReportPage', component: () => import( /* webpackChunkName: "ReportPage" */ "@/private/components/ReportPage"), meta: { requiresAuth: true, title: LAA + 'Make report' } },
			{ path: 'Worklist', name: 'Worklist', component: () => import( /* webpackChunkName: "Worklist" */ "@/private/components/Worklist"), meta: { requiresAuth: true, title: LAA + 'Worklist' } },
            { path: 'ReportOverview/:apronID', name: 'ReportOverview', component: () => import( /* webpackChunkName: "ReportOverview" */ "@/private/components/Reporting/ReportOverview"), meta: { requiresAuth: true, title: LAA + 'Report Overview' } },
            { path: 'CompareImportedData', name: 'CompareImportedData', component: () => import( /* webpackChunkName: "Worklist" */ "@/private/components/CompareImportedData"), meta: {shouldCompare: true, requiresAuth: true, title: LAA + 'Compare imported data' } },
			
		]
	},
	{
		// will match everything, 404 page
		path: '/404',
		name: 'NotFound',
		component: () => import( /* webpackChunkName: "NotFound" */ "@/shared/NotFound"),
	}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: process.env.BASE_URL,
    routes,

    scrollBehavior(to, from, savedPosition ) {
        if (to.hash) {
            return new Promise((resolve, ) => {
                setTimeout(() => {
                    resolve({ selector: to.hash, behavior: 'smooth', })
                }, 500)
            })
        }
        if (savedPosition) {
            return savedPosition
        } else {
            if (to.name == 'Quotation' || to.name == 'Quotation_p') {
                //Do not scroll to top when on the configuration page
                return null
			}
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach(async (to, from, next) => {

    if (to.name != from.name) {

        //close modal, should it be open
        Bus.emit('close-modal');
    }

    //------------------- Page Title -------------------
    //Set title
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    //------------------- meta tags -------------------
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (typeof nearestWithMeta != 'undefined') {
        if (nearestWithMeta) {
            // Turn the meta tag definitions into actual elements in the head.
            nearestWithMeta.meta.metaTags.map(tagDef => {
                const tag = document.createElement('meta');

                Object.keys(tagDef).forEach(key => {
                    tag.setAttribute(key, tagDef[key]);
                });

                // We use this to track which meta tags we create so we don't interfere with other ones.
                tag.setAttribute('data-vue-router-controlled', '');

                return tag;
            })
                // Add the meta tags to the document head.
                .forEach(tag => document.head.appendChild(tag));
        }
    }

    if (to.meta.requiresAuth) {

        if (!store.state.loginStatusActual) {
            await store.dispatch('UPDATE_IsLoggedIn')
        }

        if (store.state.isLoggedIn) {
            next();
        } else {
            const loginpath = to.fullPath;
            next({ name: 'Login', query: { redirect: loginpath } })
        }

    } else {
        next();
    }



})

export default router