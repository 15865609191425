import { createStore } from 'vuex'
import axios from 'axios'

const store = createStore({
	state: {
		blnIsImportingExcel: false,
		blnCookiesAccepted: false,
		blnCookiesRejected: false,
		isLoggedIn: false,
		isAdmin: false,
		isReadOnly: true,
		loginStatusActual: false,
		lstAllAprons: [],
		lstDiscardedAprons: [],
		lstManufacturers: [],
		lstModelsForManufacturer: [],
		lstDepartments: [],
		lstStatuses: [],
		lstSizes: [],
		lstFabricColors: [],
		lstCustomColors: [],
		fabricOptionsActual: false,
		modelsActual: false,
		lstRibbonColors: [],
		config: {},
		intDiscardedItems: 0,
		intImportItems: 0,
		checkedApronIDs: [],
		searchTerm: '',
		blnAppLoading: true,
		chosenCategory: 0,
		sku1: '',
		sku2: '',
		sku3: '',
		report: {},
		savingData: false,
		quoteCart: [],
		emptyApronModel: {}
	},
	mutations: {
		SET_loginStatusActual(state, newValue) {
			state.loginStatusActual = newValue
		},
		SET_isLoggedIn(state, newValue) {
			// mutate state
			state.isLoggedIn = newValue;
			state.loginStatusActual = true;
		},
		SET_isAdmin(state, newValue) {
			// mutate state
			state.isAdmin = newValue;
		},
		SET_isReadOnly(state, newValue) {
			// mutate state
			state.isReadOnly = newValue;
		},
		SET_lstAllAprons(state, newValue) {
			// mutate state
			state.lstAllAprons = newValue;
		},
		SET_quoteCart(state, newValue) {
			// mutate state
			state.quoteCart = newValue;
			localStorage.setItem('QuoteCart', JSON.stringify(state.quoteCart));
		},
		SET_lstDiscardedAprons(state, newValue) {
			state.lstDiscardedAprons = newValue
			state.intDiscardedItems = newValue.length
		},
		SET_chosenCategory(state, newValue) {
			state.chosenCategory = newValue;
		},
		SET_report(state, newValue) {
			state.report = newValue
		},
		SET_savingData(state, newValue) {
			state.savingData = newValue
		},
		SET_blnIsImportingExcel(state, newValue) {
			state.blnIsImportingExcel = newValue
		},
		SET_blnCookiesAccepted(state, newValue) {
			state.blnCookiesAccepted = newValue
		},
		SET_blnCookiesRejected(state, newValue) {
			state.blnCookiesRejected = newValue
		},

		ADDorUPDATE_lstAllAprons(state, { newValue, blnIsNewItem }) {
			// mutate state

			if (state.lstAllAprons.filter(apr => apr.apronID == newValue.apronID).length == 1) {
				//update

				for (var i = 0; i < state.lstAllAprons.length; i++) {
					if (state.lstAllAprons[i].apronID == newValue.apronID) {

						state.lstAllAprons.splice(i, 1);
						state.lstAllAprons.splice(i, 0, newValue);
						break;
					}
				}
			} else {
				//add
				if (blnIsNewItem == true) {
					newValue.blnIsNewItem = true;
				}

				state.lstAllAprons.push(newValue);

			}

			const lstManufIDs = state.lstManufacturers.map(x => x.manufacturerID);
			const reloadManufacturer = !lstManufIDs.includes(newValue.model.manufacturer.manufacturerID);
			const reloadCustomColor= !state.lstCustomColors.includes(newValue.customFabricColor);

			if (reloadManufacturer || reloadCustomColor) {

				axios.get('/dashboard/getCommonData').then((response) => {

						//reload manufacturer common data
						if(reloadManufacturer) {
							state.lstManufacturers = response.data.lstManufacturers
						}
						//reload customColor common data
						if(reloadCustomColor) {
							state.lstCustomColors = response.data.lstCustomColors
						}
						
					}).catch(function (error) {
						alert(error);
					}

				);

			}

		},
		ADDorUPDATE_lstDepartments(state, newValue) {
			// mutate state

			if (state.lstDepartments.filter(dept => dept.departmentID == newValue.departmentID).length == 1) {
				//update

				for (var i = 0; i < state.lstDepartments.length; i++) {
					if (state.lstDepartments[i].departmentID == newValue.departmentID) {

						state.lstDepartments.splice(i, 1);
						state.lstDepartments.splice(i, 0, newValue);
						break;
					}
				}
			} else {
				//add
				state.lstDepartments.push(newValue);
			}


		},
		ADD_checkedApronIDs(state, IdToAdd) {
			state.checkedApronIDs.push(IdToAdd)
		},
		ADDorUPDATE_quoteCart(state, newValue) {



			//Refresh first
			var QuoteCart = localStorage.getItem('QuoteCart');

			if (QuoteCart == null) {
				state.quoteCart = []

			} else {
				state.quoteCart = JSON.parse(QuoteCart)
			}



			//Find if exists
			if (state.quoteCart.filter(apr => apr.quoteApronID == newValue.quoteApronID).length == 1) {
				//update

				for (var i = 0; i < state.quoteCart.length; i++) {
					if (state.quoteCart[i].quoteApronID == newValue.quoteApronID) {

						state.quoteCart.splice(i, 1);
						state.quoteCart.splice(i, 0, newValue);
						break;
					}
				}
			} else {
				//add

				//Find new number to give to the apron

				state.quoteCart.push(newValue);
			}

			localStorage.setItem('QuoteCart', JSON.stringify(state.quoteCart));
		},
		REMOVE_checkedApronIDs(state, IdToRemove) {
			for (var i = state.checkedApronIDs.length - 1; i >= 0; i--) {
				if (IdToRemove == state.checkedApronIDs[i]) {

					state.checkedApronIDs.splice(i, 1);
					break;
				}
			}
		},
		REMOVE_lstAllAprons(state, IdsToRemove) {
			// mutate state
			for (var i = state.lstAllAprons.length - 1; i >= 0; i--) {
				if (IdsToRemove.includes(state.lstAllAprons[i].apronID)) {

					state.lstAllAprons.splice(i, 1);

				}
			}

		},
		REMOVE_lstDepartments(state, IdsToRemove) {
			// mutate state
			for (var i = state.lstDepartments.length - 1; i >= 0; i--) {
				if (IdsToRemove.includes(state.lstDepartments[i].departmentID)) {

					state.lstDepartments.splice(i, 1);

				}
			}

		},
		REMOVE_quoteCart(state, IdToRemove) {

			var siblingApronID = -1

			// mutate state
			for (var i = state.quoteCart.length - 1; i >= 0; i--) {
				if (state.quoteCart[i].quoteApronID == IdToRemove) {

					siblingApronID = state.quoteCart[i].quoteSetWith

					state.quoteCart.splice(i, 1);
					break;
				}
			}

			if (siblingApronID > 0) {
				// mutate state
				for (var j = state.quoteCart.length - 1; j >= 0; j--) {
					if (state.quoteCart[j].quoteApronID == siblingApronID) {

						state.quoteCart.splice(j, 1);
						break;
					}
				}
			}

			localStorage.setItem('QuoteCart', JSON.stringify(state.quoteCart));
		},
		SET_lstManufacturers(state, newValue) {
			// mutate state
			state.lstManufacturers = newValue;
		},
		SET_lstDepartments(state, newValue) {
			// mutate state
			state.lstDepartments = newValue;
		},
		SET_lstStatuses(state, newValue) {
			// mutate state
			state.lstStatuses = newValue;
		},
		SET_lstSizes(state, newValue) {
			// mutate state
			state.lstSizes = newValue;
		},
		SET_intDiscardedItems(state, newValue) {
			state.intDiscardedItems = newValue;
		},
		SET_intImportItems(state, newValue) {
			state.intImportItems = newValue;
		},
		SET_ApronModelsByManufacturer(state, newValue) {
			state.lstModelsForManufacturer = newValue
		},
		SET_lstFabricColors(state, newValue) {
			state.lstFabricColors = newValue
		},
		SET_lstCustomColors(state, newValue) {
			state.lstCustomColors = newValue
		},
		SET_fabricOptionsActual(state, newValue) {
			state.fabricOptionsActual = newValue
		},
		SET_modelsActual(state, newValue) {
			state.modelsActual = newValue
		},
		SET_lstRibbonColors(state, newValue) {
			state.lstRibbonColors = newValue
		},
		SET_config(state, newValue) {
			state.config = newValue
		},
		SET_emptyApronModel(state, newValue) {
			state.emptyApronModel = newValue
		},
		SET_checkedApronIDs(state, newValue) {
			state.checkedApronIDs = newValue
		},
		SET_searchTerm(state, newValue) {
			state.searchTerm = newValue
		},
		SET_sku1(state, newValue) {
			state.sku1 = newValue
		},
		SET_sku2(state, newValue) {
			state.sku2 = newValue
		},
		SET_sku3(state, newValue) {
			state.sku3 = newValue
		},
		UPDATE_Quantity(state, { apronQuoteID, NewQuanitity }) {
			for (var i = 0; i < state.quoteCart.length; i++) {
				if (state.quoteCart[i].quoteApronID == apronQuoteID) {
					state.quoteCart[i].quantity = NewQuanitity;
					break;
				}
			}

			localStorage.setItem('QuoteCart', JSON.stringify(state.quoteCart));
		},
		UPDATE_Comment(state, { apronQuoteID, newComment }) {
			for (var i = 0; i < state.quoteCart.length; i++) {
				if (state.quoteCart[i].quoteApronID == apronQuoteID) {
					state.quoteCart[i].comments = newComment;
					break;
				}
			}

			localStorage.setItem('QuoteCart', JSON.stringify(state.quoteCart));
		},
		
	},
	actions: {
		async UPDATE_IsLoggedIn({ commit }) {

			return axios({
				method: 'get',
				url: '/account/isloggedin',
			})
				.then((response) => {

					commit('SET_isLoggedIn', response.data.isLoggedIn);
					commit('SET_isAdmin', response.data.isAdmin);
					commit('SET_isReadOnly', response.data.isReadOnly);
				})
				.catch((error) => {
					alert(error);
				});

		},
		async GET_CommonData({ commit }) {

			this.state.blnAppLoading = true;

			

			return axios.get('/dashboard/getCommonData')
				.then((response) => {
					commit('SET_lstManufacturers', response.data.lstManufacturers);
					commit('SET_lstDepartments', response.data.lstDepartments);
					commit('SET_lstSizes', response.data.lstSizes);
					commit('SET_lstStatuses', response.data.lstStatuses);
					commit('SET_intDiscardedItems', response.data.intDiscardedItems);
					commit('SET_intImportItems', response.data.intImportItems);
					commit('SET_config', response.data.userConfiguration);
					commit('SET_emptyApronModel', response.data.emptyApronModel);
					commit('SET_lstCustomColors', response.data.lstCustomColors);
					
					axios.get('/dashboard/GetMyPPItems')
						.then((response) => {
							commit('SET_lstAllAprons', response.data);
							// All is commited
							this.state.blnAppLoading = false;
						})
						.catch(function (error) {
							alert(error);
						});

	

				})
				.catch(function (error) {
					alert(error);
				}
				);
					
		},
		async GET_ApronModelsByManufacturer({ commit }, payload) {

			commit('SET_modelsActual', false)

			if (payload == 0) {
				//Don't do request
				commit('SET_ApronModelsByManufacturer', [])
				commit('SET_modelsActual', true)
				return;
			}

			var params = { manufacturerID: payload }
			return axios.get('/dashboard/getApronModelsByManufacturer', { params })
				.then((response) => {
					commit('SET_ApronModelsByManufacturer', response.data)
					commit('SET_modelsActual', true)
					
				})
				.catch(function (error) {
					alert(error);
				}
				);

		},
		GET_ApronColorsByManufacturer({ commit }, payload) {
			commit('SET_fabricOptionsActual', false)

			var params = { modelID: payload }
			return axios.get('/dashboard/getApronColorsByManufacturer', { params })
				.then((response) => {

					commit('SET_lstFabricColors', response.data.lstFabricColors)
					commit('SET_lstRibbonColors', response.data.lstRibbonColors)
					commit('SET_fabricOptionsActual', true)
				})
				.catch(function (error) {
					alert(error);
				}
				);

		},
		async GET_ApronColorsByModelID({ commit }, payload) {

			commit('SET_fabricOptionsActual', false)

			if (payload.length == 0) {
				debugger;
			}

			if (payload[0] == 0) {
				commit('SET_lstFabricColors', [])
				commit('SET_lstRibbonColors', [])
				commit('SET_fabricOptionsActual', true)
				return
			}

			var params = { modelID: JSON.stringify(payload) };

			return axios.get('/dashboard/getApronColorsByModelID', { params })
				.then((response) => {
					commit('SET_lstFabricColors', response.data.lstFabricColors);
					commit('SET_lstRibbonColors', response.data.lstRibbonColors);
					commit('SET_fabricOptionsActual', true);
				})
				.catch(function (error) {
					alert(error);
				});

		},
		GET_lstDiscardedAprons({ commit }) {

			axios.get('/dashboard/getDiscardedAprons')
				.then((response) => {
					try {
						commit('SET_lstDiscardedAprons', response.data)
					}
					catch (err) {
						alert(err);
					}


				})
				.catch(function (error) {
					alert(error);
				});
		},
		GET_quoteCart({ commit }) {
			var QuoteCart = localStorage.getItem('QuoteCart');

			if (QuoteCart == null) {
				commit('SET_quoteCart', [])
			} else {
				commit('SET_quoteCart', JSON.parse(QuoteCart))
			}

			
		},
		

	},
	modules: {
	},
	getters: {
		ApronInspectionNeeded: state => {
			const dateNow = new Date()
			return state.lstAllAprons.filter(apron => apron.status == 2 || Math.abs(Date.parse(apron.nextInspection) - dateNow) < 2592000000)
		},
		ApronsOK: state => {
			return state.lstAllAprons.filter(apron => apron.status == 1)
		},
		ApronsAwayForRepairs: state => {
			return state.lstAllAprons.filter(apron => apron.status == 3)
		},
		ApronOkForNow: state => {
			return state.lstAllAprons.filter(apron => apron.status == 6)
		},
		CheckedAprons: state => {
			var returnvalue1 = state.lstAllAprons.filter(apron => state.checkedApronIDs.includes(apron.apronID))
			var returnvalue2 = state.lstDiscardedAprons.filter(apron => state.checkedApronIDs.includes(apron.apronID))

			return returnvalue1.concat(returnvalue2)

		},
		isOutOfTolerance: state => {
			if (state.config.tolerance == null) {
				return true
			}

			if (state.report.scoreHygiene > 0 && state.report.scoreHygiene < state.config.tolerance.limHygiene) {
				return true;
			}

			if (state.report.scoreFasteners > 0 && state.report.scoreFasteners < state.config.tolerance.limFasteners) {
				return true;
			}

			if (state.report.scoreProtection > 0 && state.report.scoreProtection < state.config.tolerance.limProtection) {
				return true;
			}

			return false
		},
		isOnEdge: state => {
			if (state.report.scoreHygiene > 0 && state.report.scoreHygiene == state.config.tolerance.limHygiene && state.report.scoreHygiene < 5) {
				return true;
			}

			if (state.report.scoreFasteners > 0 && state.report.scoreFasteners == state.config.tolerance.limFasteners && state.report.scoreFasteners < 5) {
				return true;
			}

			if (state.report.scoreProtection > 0 && state.report.scoreProtection == state.config.tolerance.limProtection && state.report.scoreProtection < 5) {
				return true;
			}

			return false
		},
		allowSubmitReport: (state, getters) => {
			
			if (getters.isOutOfTolerance || getters.isOnEdge) {
				if (new Date(getters.ultimatum) < new Date(state.report.nextInspection)) {
					return false
				}
			}

			return true
		},
		ultimatum: function() {
			return new Date().setDate(new Date().getDate() + 367)
		},

		processedQuoteCart: function (state) {
			var returnValue = []

			//filter out combos
			for (var i = 0; i < state.quoteCart.length; i++) {
				if (returnValue.filter(x => x.quoteApronID == state.quoteCart[i].quoteSetWith).length == 0) {
					//Add
					returnValue.push(state.quoteCart[i])
				}
			}

			return returnValue
		}
	},
})

export default store